import React, { useEffect } from 'react'
import style from "./about.module.css"
import { Link } from 'react-router-dom'
import { Right } from '../../Components/Common/Right'
import { WOW } from "wowjs"

export const About = () => {
  useEffect(() => {
    const wow = new WOW()
    wow.init()
  }, [])
  return (
    <main className={style.about + " d-flex flex-column flex-xl-row"}>
      <div className={style.left + " d-flex align-items-end"}>
        <div className={style.social + ' d-flex justify-content-between align-items-center flex-grow-1'}>
          <p className='mb-0'>Follow</p>
          <div className='d-flex gap-3'>
            <Link to="#" target="_blank">
              <i className="bi bi-twitter-x"></i>
            </Link>
            <Link to="#" target="_blank">
              <i className="bi bi-linkedin"></i>
            </Link>
            <Link to="#" target="_blank">
              <i className="bi bi-telegram"></i>
            </Link>
          </div>
        </div>
      </div>
      <div className={style.center + " flex-grow-1"}>
        <h1 className='wow animate__animated animate__fadeInUp'>Alien.fi is a research-driven crypto investment firm.</h1>
        <div className='wow animate__animated animate__fadeInUp'>
          <p>We operate on the cutting edge, driven by the conviction that technology fuels progress.</p>
          <p>We invest in crypto companies and protocols from their earliest stages—often when they’re still just a concept.</p>
          <p>But we’re more than just investors: <strong>we’re builders</strong>. Many of us have contributed to tools, protocols, and companies that have become essential to the crypto landscape. We create software—often open source—with the goal of pushing crypto’s frontier forward for everyone.</p>
          <p>Research underpins everything we do. We believe that true innovation demands depth and thrives at the edges—not in boardrooms or ivory towers. Whether it’s collaborating on a research paper, shipping code, or advising on product and business strategy, we’re all in.</p>
          <p>If you’re building something bold at the frontier, come build it with us.</p>
        </div>
      
      </div>
      
      <Right />
    </main>
  )
}
