import React from 'react'
import style from "./career.module.css"
import { Link } from 'react-router-dom'

export const Card = ({ data, no }) => {
    return (
        <div className={style.card + ' d-flex gap-3 animate__animated animate__fadeInDown'}>
            <div className={style.imgdiv + " d-flex justify-content-center align-items-center"}>
                <img src={data.img || "/assets/souce1.svg"} width="100%" alt="" />
            </div>
            <div className='flex-grow-1'>
                <div>
                    <h2>{data.title}</h2>
                </div>
                <div className={style.content + ' d-flex justify-content-between align-items-center'}>
                    <div className='d-flex gap-4'>
                        <p className='mb-0'>Simple Labs</p>
                        <p className='mb-0 fw-normal'>New York, NY, USA</p>
                    </div>
                    <Link to={"/careers/"+no} className='text-decoration-none text-dark d-flex align-items-center'>
                        <span className='me-2'>Apply</span>
                        <span className='d-flex justify-content-center align-items-center'>
                            <svg width="11" height="11" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.203125 4.77783H43.4727V48.0431" strokeWidth="9" />
                                <path d="M39.5625 8.47949L4.44141 42.8491" strokeWidth="9" />
                            </svg>
                        </span>
                    </Link>
                </div>
            </div>
        </div>
    )
}
