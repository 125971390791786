import React from 'react'
import style from "./navFooter.module.css"
import { Link } from 'react-router-dom'

export const Footer = () => {
    return (
        <footer className={style.footer}>
            <div className='d-flex flex-column flex-lg-row justify-content-between'>
                <div className='d-flex align-items-start gap-5'>
                    <div className={style.logo+" d-flex gap-2 align-items-start position-relative" }>

                        <img src="/assets/logo1.svg" alt="" />
                        <span>Alien.fi</span>
                        <i className='position-absolute'>TM</i>

                    </div>
                    <div className={style.routediv + " d-flex gap-4"}>
                        <ul className='list-unstyled text-start' >
                            <li>
                                <Link to="/about">About</Link>
                            </li>
                            <li>
                                <Link to="/team">Team</Link>
                            </li>
                            <li>
                                <Link to="portfolio">Portfolio</Link>
                            </li>
                        </ul>
                        <ul className='list-unstyled text-start' >
                            <li>
                                <Link to="/writing">Writing</Link>
                            </li>
                            <li>
                                <Link to="/open-source">Open Source</Link>
                            </li>
                            <li>
                                <Link to="/careers">Careers</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={style.rights}>
                    <p className='mb-0'><strong>Copyright © 2025 Alien.fi. All rights reserved.</strong> Alien.fi and its associated logos are trademarks of Alien.fi. All other product names, logos, and brands mentioned on this website are the property of their respective owners. Unauthorized use of any material is strictly prohibited.</p>
                </div>
            </div>
        </footer>
    )
}
