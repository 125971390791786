import React from 'react'
import style from "./job.module.css"

export const Form = () => {
    return (
        <div className={style.form}>
            <form action="">
                <div>
                    <p>Name</p>
                    <div className='d-flex flex-wrap justify-content-between gap-sm-3 gap-2'>
                        <input type="text" placeholder='First Name' />
                        <input type="text" placeholder='Second Name' />
                    </div>
                </div>
                <div>
                    <p>Contact Info</p>
                    <div className='d-flex justify-content-between flex-wrap gap-sm-3 gap-2'>
                        <input type="email" placeholder='Email' />
                        <input type="number" placeholder='Phone' />
                        <textarea className='w-100' rows="4" name="" placeholder='Current Address' id=""></textarea>
                    </div>
                </div>
                <div>
                    <p>Current Details</p>
                    <div className='d-flex justify-content-between flex-wrap gap-sm-3 gap-2'>
                        <input type="text" placeholder='Current Company' />
                        <input type="text" placeholder='Current Role' />
                    </div>
                    <div className={style.drager+' d-flex justify-content-center align-items-center gap-1'}>
                        <button className='position-relative'>Upload Resume
                            <input type="file" className='position-absolute w-100 h-100 top-0 start-0 opacity-0' style={{cursor:"pointer"}}/>
                        </button>
                        <p className='mb-0 opacity-50'>or drag and drop here</p>
                    </div>
                </div>
                <div>
                    <p>Additional Info</p>
                    <div className='d-flex flex-wrap justify-content-between gap-sm-3 gap-2'>
                        <input type="text" placeholder='Linked In' />
                        <input type="text" placeholder='Portfolio Link' />
                        <textarea className='w-100' rows={5} name="" placeholder='Additional Info or Notes' id=""></textarea>
                    </div>
                </div>
                <button type='submit' className='bg-black border-0 text-white w-100 d-flex justify-content-between'>
                    Submit Application
                    <span className='d-flex justify-content-center align-items-center'>
                        <svg width="11" height="11" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.203125 4.77783H43.4727V48.0431" strokeWidth="9" />
                            <path d="M39.5625 8.47949L4.44141 42.8491" strokeWidth="9" />
                        </svg>
                    </span>
                </button>
            </form>

        </div>
    )
}
