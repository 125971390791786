import React from 'react'
import { Link } from 'react-router-dom'
import style from "./team.module.css"

export const Person = ({ person }) => {
    return (
        <div className={style.card + ' d-flex gap-md-3 gap-sm-2 gap-3 align-items-center animate__animated animate__fadeInDown'}>
            <div className={style.imgdiv+" h-100 p-2"}>
                <img src={"/assets/logo1.svg"} className='rounded' alt="" />
            </div>
            <div className={style.content + ' d-flex flex-column justify-content-between'}>
                <h3>{person.name}</h3>
                <div className='mt-4'>

                    {person.position.map((item, i) => (
                        <p key={i}>{i > 0 ? "& " + item : item}</p>
                    ))}

                    {/* <div className='d-flex gap-3'>
                        <Link to="#" target="_blank">
                            <img src="/assets/twitter.svg" alt="" />
                        </Link>
                        <Link to="#" target="_blank">
                            <img src="/assets/linkdin.svg" alt="" />
                        </Link>
                        <Link to="#" target="_blank">
                            <img src="/assets/telegram.svg" alt="" />
                        </Link>
                    </div> */}
                </div>
            </div>
        </div>
    )
}
