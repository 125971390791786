import React from 'react'
import style from "./job.module.css"

export const Detail = ({data}) => {
    return (
        <div className={style.detail}>
            <h1>{data.title}</h1>
            <div className='mt-3 d-flex justify-content-between'>
                <p>Research </p>
                <p><span>07.25.2023</span> <span>Alien.fi</span></p>
            </div>
            <p>{data.description}</p>

            <h6>Responsibilities:</h6>
            <ul>
                {
                    data.responsibilities.map((item, i) => (
                        <li key={i}>{item}</li>
                    ))
                }
            </ul>
            <h6>Requirements:</h6>
            <ul>
                {
                    data.requirements.map((item, i) => (
                        <li key={i}>{item}</li>
                    ))
                }
            </ul>
        </div>
    )
}
