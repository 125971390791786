import logo from './logo.svg';
import 'animate.css';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home } from './Pages/Home/Home.jsx';
import { Navbar } from './Components/Common/Navbar';
import { Footer } from './Components/Common/Footer';
import { About } from './Pages/About/About.jsx';
import { Team } from './Pages/Team/Team.jsx';
import { Portfollio } from './Pages/Portfollio/Portfollio.jsx';
import { Writing } from './Pages/Writing/Writing.jsx';
import { OpenSource } from './Pages/OpenSource/OpenSource.jsx';
import { Career } from './Pages/Career/Career.jsx';
// import { Artical } from './Pages/Artical/Artical.jsx';
import { Job_detail } from './Pages/JobDetail/Job_detail.jsx';
import { Artical1 } from './Pages/Artical/Artical1.jsx';
import { Artical2 } from './Pages/Artical/Artical2.jsx';
import { Artical3 } from './Pages/Artical/Artical3.jsx';
import { Artical4 } from './Pages/Artical/Artical4.jsx';


// https://docs.google.com/document/d/1DLtx4ZxXUTQx0H8NE9xjGBnB_lAaKmYxGXfDB2uxHOs/edit?tab=t.0
function App() {
  return (
    <div className="App">
     <BrowserRouter>
     <Navbar/>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/team" element={<Team/>}/>
        <Route path="/portfolio" element={<Portfollio/>}/>
        <Route path='/writing' element={<Writing/>}/>
        <Route path="/open-source" element={<OpenSource/>}/>
        <Route path='/careers' element={<Career/>}/>
        {/* <Route path="/writing/alienfi-files-amicus-supporting-nft-creators-in-legal-battle" element={<Artical1/>}/>
        <Route path="/writing/alienfi-files-amicus-to-support-keeping-election-markets-in-the-us" element={<Artical2/>}/>
        <Route path="/writing/the-5-levels-of-secure-smart-contract-development" element={<Artical3/>}/>
        <Route path="/writing/alienfi-provides-amicus-support-for-def-and-beba’s-lawsuit-against-the-sec" element={<Artical4/>}/> */}
        <Route path='/careers/:id' element={<Job_detail/>}/>
      </Routes>
      <Footer/>
     </BrowserRouter>
    </div>
  );
}

export default App;
