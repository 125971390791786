import React, { useState, useEffect } from 'react'
import style from "./career.module.css"
import { Card } from './Card'
import { Right } from '../../Components/Common/Right'
import { Link } from 'react-router-dom'
import {WOW} from "wowjs"


const content = [
    {
        title:"Blockchain Developer",
        img:"https://cdn-icons-png.freepik.com/512/6298/6298144.png",
    },
    {
        title:"Smart Contract Engineer",
        img:"https://cdn-icons-png.flaticon.com/512/5901/5901995.png",
    },
    {
        title:"UI/UX Designer",
        img:"https://cdn3d.iconscout.com/3d/premium/thumb/ui-ux-3d-icon-download-in-png-blend-fbx-gltf-file-formats--web-design-development-programming-computer-graphic-pack-icons-9831982.png",
    },
    {
        title:"Marketing Manager (Web3)",
        img:"https://img.freepik.com/premium-vector/circular-marketing-icon_1453-93.jpg",
    },
    {
        title:"Community Manager (Web3)",
        img:"https://cdn-icons-png.freepik.com/256/15329/15329462.png?semt=ais_hybrid",
    },
    {
        title:"Full-Stack Developer",
        img:"https://cdn2.iconfinder.com/data/icons/ux-and-ui-astute-vol-1/512/Full_Stack_Developer-512.png",
    }
]


export const Career = () => {
    const [fillter, setFilter] = useState({
        alien: false,
        blokchain_developer: false,
        // administration: false,
        sce: false,
        ui_ux: false,
        marketing_manager: false,
        community_manager: false,
        full_stack_developer:false
    })
    const fillter_handler = (name) => {
        if (!fillter[name]) {
            console.log(name)
            setFilter({
                ...fillter,
                [name]: true,
            })
        } else {
            setFilter({
                ...fillter,
                [name]: false
            })
        }
    }

    useEffect(()=>{
        const wow = new WOW()
        wow.init()
      },[])
    return (
        <main className={style.career + " d-flex flex-wrap flex-xl-nowrap"}>
            <div className={style.left + " d-flex flex-column justify-content-between"}>
                <div className={style.top + " px-3 py-4"}>
                    <h3 className='text-uppercase mb-4'>Careers</h3>
                    <p>Join the innovative team at Alien.fi and help shape the future of decentralized finance. Explore exciting opportunities to work at the cutting edge of blockchain technology and DeFi solutions.</p>

                    <div>
                        <input type="text" placeholder='Title or Company' />
                        <input type="text" placeholder='Locations' />
                        <div className='d-flex gap-2'>
                            <div className={`${style.checkbox} ${fillter.alien && style.active}`} onClick={() => { fillter_handler("alien") }}>
                                <img src="/assets/check.svg" alt="" />
                            </div>
                            <p>Career at Alien.fi</p>
                        </div>
                        <div className='d-flex gap-2  mt-4'>
                            <div className={`${style.checkbox} ${fillter.blokchain_developer && style.active}`} onClick={() => { fillter_handler("blokchain_developer") }}>
                                <img src="/assets/check.svg" alt="" />
                            </div>
                            <p>Blokchain Developer</p>
                        </div>
                        <div className='d-flex gap-2'>
                            <div className={`${style.checkbox} ${fillter.sce && style.active}`} onClick={() => { fillter_handler("sce") }}>
                                <img src="/assets/check.svg" alt="" />
                            </div>
                            <p>Smart Contract Engineer</p>
                        </div>
                        <div className='d-flex gap-2'>
                            <div className={`${style.checkbox} ${fillter.ui_ux && style.active}`} onClick={() => { fillter_handler("ui_ux") }}>
                                <img src="/assets/check.svg" alt="" />
                            </div>
                            <p>UI/UX Designer </p>
                        </div>
                        <div className='d-flex gap-2'>
                            <div className={`${style.checkbox} ${fillter.marketing_manager && style.active}`} onClick={() => { fillter_handler("marketing_manager") }}>
                                <img src="/assets/check.svg" alt="" />
                            </div>
                            <p>Marketing Manager</p>
                        </div>
                        <div className='d-flex gap-2'>
                            <div className={`${style.checkbox} ${fillter.community_manager && style.active}`} onClick={() => { fillter_handler("community_manager") }}>
                                <img src="/assets/check.svg" alt="" />
                            </div>
                            <p>Community Manager </p>
                        </div>
                        <div className='d-flex gap-2'>
                            <div className={`${style.checkbox} ${fillter.full_stack_developer && style.active}`} onClick={() => { fillter_handler("full_stack_developer") }}>
                                <img src="/assets/check.svg" alt="" />
                            </div>
                            <p>Full Stack Developer </p>
                        </div>
                    </div>
                </div>
                <div className={style.social + " d-flex p-4 justify-content-between align-items-center w-100"}>
                    <p className='mb-0'>Follow</p>
                    <div className='d-flex gap-3'>
                        <Link to="#" target="_blank">
                            <i className="bi bi-twitter-x"></i>
                        </Link>
                        <Link to="#" target="_blank">
                            <i className="bi bi-linkedin"></i>
                        </Link>
                        <Link to="#" target="_blank">
                            <i className="bi bi-telegram"></i>
                        </Link>
                    </div>
                </div>
            </div>
            <div className={style.center + " flex-grow-1"}>
                {
                    content.map((item,index)=>(
                        <Card data={item} key={index} no={index}/>
                    ))
                }
            </div>
            {/* <Right /> */}
        </main>
    )
}
