import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import style from "./source.module.css"
import { Right } from '../../Components/Common/Right'
import { Card } from './Card'
import { WOW } from "wowjs"


const content = [
  {
    title: "Foundry",
    text: "Revolutionizing Ethereum development, Foundry is a blazing-fast, portable, and modular toolkit for Ethereum developers.",
    imgLink:"https://getfoundry.sh/logo.png",
    git:"https://github.com/foundry-rs/",
    doc:"https://book.getfoundry.sh/",
    web:"https://getfoundry.sh/",
    bg:"#ffffff"
  },
  {
    title: "Ethers.js",
    text: "A powerful library for Ethereum application development, Ethers.js provides a simple yet comprehensive API for interacting with Ethereum.",
    imgLink:"https://docs.ethers.org/v6/static/logo.svg",
    git:"https://github.com/ethers-io/ethers.js",
    doc:"https://docs.ethers.org/v5/",
    web:"https://docs.ethers.org/",
    bg:"#0f379c",
  },
  {
    title: "Optimism Libraries",
    text: "Build on Optimism with our dedicated libraries that enable high-speed and low-cost Ethereum transactions.",
    // imgLink:"https://www.optimism.io/optimism.svg",
    imgLink:"/assets/portfolio_img/optimism.svg",
    git:"https://github.com/ethereum-optimism/optimism",
    web:"https://www.optimism.io/",
    bg:"#ff0420"
  },
  {
    title: "Wagmi",
    text: "Developer library with over 20 hooks for working with wallets, contracts, transactions, signing, and more",
    imgLink:"https://wagmi.sh/logo-dark.svg",
    git:"https://github.com/wevm/wagmi",
    doc:"https://wagmi.sh/",
    bg:"#1b1b1f"
  },
  {
    title: "Flux",
    text: "Graph-based LLM power tool for exploring many completions in parallel",
    imgtext:"Flux",
    git:"https://github.com/paradigmxyz/flux",
    web:"https://flux.paradigm.xyz/",
    bg:"#ffffff"
  },
]


export const OpenSource = () => {
  useEffect(() => {
    const wow = new WOW()
    wow.init()
  }, [])
  return (
    <main className={style.writing + " d-flex flex-wrap flex-xl-nowrap "}>
      <div className={style.left + " d-flex flex-column justify-content-between"}>
        <div className={style.top + " p-sm-4 p-3 wow animate__animated animate__fadeInUp"}>
          <p className='para'>Alien.fi builds and contributes to projects that advance the frontier.</p>
          <p className='para'>We believe in doing so even when there may not be a direct commercial incentive.</p>
        </div>
        <div className={style.social + " d-flex p-4 justify-content-between align-items-center w-100"}>
          <p className='mb-0'>Follow</p>
          <div className='d-flex gap-3'>
            <Link to="#" target="_blank">
              <i className="bi bi-twitter-x"></i>
            </Link>
            <Link to="#" target="_blank">
              <i className="bi bi-linkedin"></i>
            </Link>
            <Link to="#" target="_blank">
              <i className="bi bi-telegram"></i>
            </Link>
          </div>
        </div>
      </div>
      <div className={style.center + " flex-wrap flex-grow-1"}>
        {
          content.map((item, index)=>(
            <Card data={item} key={index}/>
          ))
        }
  
      </div>
      {/* <Right /> */}
    </main>
  )
}
