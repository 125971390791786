import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Right } from '../../Components/Common/Right'
import style from "./job.module.css"
import { Detail } from './Detail'
import { Form } from './Form'


const data = [
    {
        title: "Blockchain Developer",
        description: "We are seeking a Blockchain Developer to join our dynamic team at Alien.fi. As a Blockchain Developer, you will be responsible for designing, implementing, and maintaining blockchain-based solutions. You will work closely with other developers to ensure the secure and efficient operation of the platform, collaborating on all aspects of the development cycle.",
        responsibilities: [
            "Develop, test, and deploy decentralized applications (dApps) on blockchain platforms.",
            "Ensure the integrity and security of blockchain solutions.",
            "Collaborate with the team to design smart contracts and integration strategies.",
            "Optimize blockchain protocols and keep up with new trends in blockchain technology.",
            "Write clean, scalable, and efficient code to support the platform's decentralized features."
        ],
        requirements: [
            "Proficiency in blockchain technologies such as Ethereum, Solana, or other Web3 platforms.",
            "Experience with Solidity, smart contracts, and decentralized finance (DeFi).",
            "Strong problem-solving skills and ability to write efficient code.",
            "Knowledge of cryptography and security best practices.",
            "Previous experience in the Web3 space or blockchain development is a plus."
        ]
    },
    {
        title: "Smart Contract Engineer",
        description: "Alien.fi is looking for an experienced Smart Contract Engineer to help us build the next generation of decentralized finance (DeFi) applications. In this role, you will be responsible for writing and auditing smart contracts, ensuring their security, and working on both front-end and back-end integrations for our platform.",
        responsibilities: [
            "Develop and maintain secure smart contracts for various DeFi protocols.",
            "Collaborate with cross-functional teams to design and implement features.",
            "Conduct audits on existing smart contracts to identify vulnerabilities and improve performance.",
            "Stay up to date with new smart contract standards and emerging technologies.",
            "Implement automated tests and simulations to ensure contract reliability."
        ],
        requirements: [
            "Strong knowledge of Ethereum, Solidity, and other smart contract platforms.",
            "Experience with smart contract testing frameworks such as Truffle or Hardhat.",
            "Familiarity with decentralized applications (dApps) and Web3.js.",
            "Ability to write efficient, secure, and well-documented code.",
            "Experience in deploying smart contracts to the mainnet and testnets."
        ]
    },
    {
        title: "UI/UX Designer",
        description: "We are looking for a talented UI/UX Designer to join Alien.fi and help shape the user experience for our decentralized finance platform. You will work closely with developers and product managers to design interfaces that are both functional and visually appealing.",
        responsibilities: [
            "Design engaging and intuitive user interfaces for our Web3 applications.",
            "Create wireframes, prototypes, and high-fidelity designs.",
            "Conduct user research and usability testing to gather feedback and improve designs.",
            "Collaborate with developers to ensure design feasibility and effective implementation.",
            "Stay updated on industry trends and ensure designs are aligned with the latest UX practices.",
        ],
        requirements: [
            "Proven experience as a UI/UX Designer, preferably in Web3 or fintech.",
            "Strong knowledge of design tools like Figma, Sketch, or Adobe XD.",
            "Experience with responsive web design and mobile-first strategies.",
            "Understanding of user-centered design principles and methodologies.",
            "Experience designing Web3 applications is a plus.",
        ]
    },
    {
        title: "Marketing Manager (Web3)",
        description: "As a Marketing Manager (Web3) at Alien.fi, you will be responsible for building and executing marketing strategies to promote our platform to the global Web3 community. You will work with both traditional and crypto-native channels to create brand awareness and drive user engagement.",
        responsibilities: [
            "Develop and execute digital marketing campaigns for the Web3 space.",
            "Work on influencer marketing, partnerships, and community engagement.",
            "Manage social media accounts and create content to raise awareness about Alien.fi.",
            "Collaborate with internal teams to align marketing strategies with business goals.",
            "Track, analyze, and report on marketing campaign performance.",
        ],
        requirements: [
            "Experience in Web3, cryptocurrency, or blockchain marketing.",
            "Strong understanding of digital marketing, including SEO, SEM, social media, and email marketing.",
            "Experience managing Web3 communities on platforms like Discord, Telegram, and Twitter.",
            "Excellent communication and writing skills.",
            "Knowledge of DeFi or NFT marketing trends is a plus.",
        ]
    },
    {
        title: "Community Manager (Web3)",
        description: "Alien.fi is looking for a passionate Community Manager to lead and grow our Web3 community. You will be the face of the platform, interacting with users and building a strong, engaged community across our social media channels.",
        responsibilities: [
            "Engage with the community on social platforms like Discord, Telegram, Twitter, and Reddit.",
            "Organize and lead community events, AMAs, and promotions.",
            "Act as a liaison between the community and the product team.",
            "Gather feedback and insights from the community to help improve the platform.",
            "Monitor community sentiment and respond to inquiries and issues in a timely manner.",
        ],
        requirements: [
            "Proven experience as a community manager in the Web3 or blockchain space.",
            "Strong communication skills and the ability to engage with users.",
            "Familiarity with Web3 platforms and decentralized communities.",
            "Experience managing online communities on platforms like Discord, Telegram, and Twitter.",
            "A passion for crypto, DeFi, and Web3 technologies.",
        ]
    },
    {
        title: "Full-Stack Developer",
        description: "Alien.fi is seeking a skilled Full-Stack Developer to join our team and build decentralized applications for our platform. You will be responsible for both the front-end and back-end development, working on cutting-edge blockchain technology to build scalable solutions.",
        responsibilities: [
            "Develop and maintain front-end and back-end components of dApps.",
            "Work with blockchain developers to integrate smart contracts into the platform.",
            "Build user-friendly interfaces using modern frameworks and libraries.",
            "Optimize the application performance and scalability.",
            "Collaborate with other developers to design the architecture and implement best practices.",
        ],
        requirements: [
            "Proficiency in JavaScript, React, Node.js, and related web development technologies.",
            "Strong experience with blockchain integration and smart contract platforms.",
            "Familiarity with dApp frameworks such as Web3.js or Ethers.js.",
            "Experience with API integrations and working with decentralized networks.",
            "Strong problem-solving skills and a proactive attitude.",
        ]
    }
]






export const Job_detail = () => {
    const [isform, setForm] = useState(false)
    const { id } = useParams()
    return (
        <main className={style.artical + " d-flex  flex-wrap flex-xl-nowrap"}>
            <div className={style.left + " d-flex flex-column justify-content-between"}>
                <div className={style.top + " wow animate__animated animate__fadeInUp"}>
                    <div className='d-flex justify-content-between'>
                        <span className='d-flex justify-content-center align-items-center'>
                            <svg width="11" height="11" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.203125 4.77783H43.4727V48.0431" strokeWidth="9" />
                                <path d="M39.5625 8.47949L4.44141 42.8491" strokeWidth="9" />
                            </svg>
                        </span>
                        <Link to={"/careers"} className='text-decoration-none text-dark'>Go Back to List</Link>
                    </div>
                    <div className='py-4'>
                        <h5 className='mb-0'>Finance Manager</h5>
                    </div>
                    <div>
                        <span>Location</span>
                        <p>San Francisco, CA</p>
                    </div>
                    <div>
                        <span>Type</span>
                        <p>Full time</p>
                    </div>
                    <div>
                        <span>Department</span>
                        <p>ALien.fi</p>
                    </div>
                    <div>
                        <span>Compensation</span>
                        <p>$160K – $180K</p>
                        <span>Actual starting pay may vary based on a range of factors which can include experience, skills, and scope. This role is eligible for additional performance-based compensation as well as a comprehensive benefits package.</span>
                    </div>
                </div>
                <div className={style.social + " d-flex p-4 align-items-center justify-content-between w-100"}>
                    <p className='mb-0'>Follow</p>
                    <div className='d-flex gap-3'>
                        <Link to="#" target="_blank">
                            <i className="bi bi-twitter-x"></i>
                        </Link>
                        <Link to="#" target="_blank">
                            <i className="bi bi-linkedin"></i>
                        </Link>
                        <Link to="#" target="_blank">
                            <i className="bi bi-telegram"></i>
                        </Link>
                    </div>
                </div>
            </div>
            <div className={style.center + " flex-grow-1"}>
                <div className={style.navigate_div+" d-flex gap-sm-4 justify-content-between justify-content-sm-start"}>
                    <button className={`${!isform&&style.active} border-0 bg-transparent d-flex align-items-center gap-2`}
                        onClick={()=>{setForm(false)}}
                    >
                        Job Description
                        <span className='d-flex justify-content-center align-items-center'>
                            <svg width="11" height="11" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.203125 4.77783H43.4727V48.0431" strokeWidth="9" />
                                <path d="M39.5625 8.47949L4.44141 42.8491" strokeWidth="9" />
                            </svg>
                        </span>
                    </button>
                    <button className={`${isform&&style.active} border-0 bg-transparent d-flex align-items-center gap-2`}
                        onClick={()=>{setForm(true)}}
                    >
                        Apply
                        <span className='d-flex justify-content-center align-items-center'>
                            <svg width="11" height="11" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.203125 4.77783H43.4727V48.0431" strokeWidth="9" />
                                <path d="M39.5625 8.47949L4.44141 42.8491" strokeWidth="9" />
                            </svg>
                        </span>
                    </button>
                </div>
                { !isform?<Detail data={data[id]}/> 
                :<Form/>
                }
            </div>
            {/* <Right /> */}
        </main>
    )
}
