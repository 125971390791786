import React, { useEffect } from 'react'
import style from "./writing.module.css"
import { Right } from '../../Components/Common/Right'
import { Link } from 'react-router-dom'
import { ArCard } from './ArCard'
import { WOW } from "wowjs"

const content = [
  {
    title: "VCs vs. DAOs: The Unfinished Battle for Crypto Governance",
    text: "Crypto was designed to be trustless and decentralized, yet the reality is often far from this ideal. The industry’s most successful projects are frequently backed by venture capitalists (VCs) — entities that thrive on centralization and financial control.",
    link: "https://medium.com/@alienfi271/vcs-vs-daos-the-unfinished-battle-for-crypto-governance-3ee029a2cc54",
    imgLink:"https://miro.medium.com/v2/resize:fit:720/format:webp/1*INbpOx32pwQLvDZiWoyL1Q.jpeg"
  },
  {
    title: "ZK vs. Optimistic Rollups: Why the Debate is Just Getting Started",
    text: "Ethereum’s success comes with a major tradeoff — scalability. As more users onboard, gas fees surge, transactions slow down, and network congestion increases.",
    link: "https://medium.com/@alienfi271/zk-vs-optimistic-rollups-why-the-debate-is-just-getting-started-50de26e6b38b",
    imgLink:"https://miro.medium.com/v2/resize:fit:720/format:webp/1*P_C9N6EdhYPNjrzahs-vNQ.jpeg"
  },
  {
    title: "Account Abstraction: The Biggest UX Upgrade No One is Talking About",
    text: "Crypto adoption is growing, but there’s one major problem — blockchain wallets are hard to use. From private keys to gas fees, the user experience (UX) is frustrating for both newcomers and experienced users.",
    imgLink:"https://miro.medium.com/v2/resize:fit:720/format:webp/1*T8CMExXx6M1VH01Z3llGEg.jpeg",
    link :"https://medium.com/@alienfi271/account-abstraction-the-biggest-ux-upgrade-no-one-is-talking-about-bf3df70f7b2f",
  },
  {
    title: "The 5 Levels of Secure Smart Contract Development",
    text: "At AlienFi, we believe technology fuels progress, and nowhere is this clearer than in the realm of smart contract security.",
    imgLink:"https://miro.medium.com/v2/resize:fit:720/format:webp/1*gqAVAo9lB9S-QbGp-Jbigw.jpeg",
    link: "https://medium.com/@alienfi271/the-5-levels-of-secure-smart-contract-development-e87415255fdb"
  },
  {
    title: "AlienFi & The Power of Open-Source: Why We Build in Public",
    text: "At AlienFi, we operate on the cutting edge, convinced that technology fuels progress. One of the core ways we push this progress forward is by building in public — contributing to and publishing open-source software for everyone to explore, adapt, and improve",
    imgLink:"https://miro.medium.com/v2/resize:fit:720/format:webp/1*bKopEwqnF5hYRZnKNErp9A.jpeg",
    link: "https://medium.com/@alienfi271/alienfi-the-power-of-open-source-why-we-build-in-public-1ec256aaaa2e"
  },
]

export const Writing = () => {
  useEffect(() => {
    const wow = new WOW()
    wow.init()
  }, [])
  return (
    <main className={style.writing + " d-flex  flex-wrap flex-xl-nowrap"}>
      <div className={style.left + " d-flex flex-column justify-content-between"}>
        <div className={style.top + " p-sm-4 p-3 wow animate__animated animate__fadeInUp"}>
          <p className='para'>Alien.fi builds and contributes to projects that advance the frontier.</p>
          <p className='para'>We believe in doing so even when there may not be a direct commercial incentive.</p>
        </div>
        <div className={style.social + " d-flex p-4 align-items-center justify-content-between w-100"}>
          <p className='mb-0'>Follow</p>
          <div className='d-flex gap-3'>
            <Link to="#" target="_blank">
              <i className="bi bi-twitter-x"></i>
            </Link>
            <Link to="#" target="_blank">
              <i className="bi bi-linkedin"></i>
            </Link>
            <Link to="#" target="_blank">
              <i className="bi bi-telegram"></i>
            </Link>
          </div>
        </div>
      </div>
      <div className={style.center + " flex-wrap flex-grow-1"}>
        {
          content.map((item, index) => (
            <ArCard key={index} data={item} />
          ))
        }
      </div>
      {/* <Right /> */}
    </main>
  )
}
