import React, { useEffect, useState } from 'react'
import style from "./team.module.css"
import { Right } from '../../Components/Common/Right'
import { Link } from 'react-router-dom'
import { Person } from './Person'
import {WOW} from "wowjs"

const person = [
    {
        name:"Thomas Alien",
        position:["Co-Founder & Managing Partner"],
        imgLink:"/assets/team/thomas_alien.jpg",
        category:["Leadership"]
    },
    {
        name:"Sophia Vega",
        position:["Chief Technology Officer (CTO)"],
        imgLink:"/assets/team/sophia_vega.png",
        category:["Leadership"]
    },
    {
        name:"Mason Hunt",
        position:["Chief Strategy Officer (CSO)"],
        imgLink:"/assets/team/mason_hunt.png",
        category:["Leadership"]
    },
    {
        name:"Isabella Brooks",
        position:["Chief Financial Officer (CFO)","Chief Legal Officer"],
        imgLink:"/assets/team/isabella_brooks.png",
        category:["Leadership","Legal & Policy"]
    },
    {
        name:"Jaxon Carter",
        position:["Head of Research & Development (R&D)"],
        imgLink:"/assets/team/jaxson_carter.png",
        category:[]
    },
    {
        name:"Liam Gray",
        position:["Head of Security & Compliance"],
        imgLink:"/assets/team/laim_gray.png",
        category:["Talent"]
    },
    // {
    //     name:"Zara Blake",
    //     position:["Head of Community Engagement","Head of Design & User Experience"],
    //     imgLink:"/assets/team/zara_blake.png",
    //     category:["Talent","Design"]
    // },
    // {
    //     name:"Lucas Brooks",
    //     position:["Director of Marketing & Brand Strategy","Head of Regulatory Affairs"],
    //     imgLink:"/assets/team/lucas_brooks.png",
    //     category:["Legal & Policy"]
    // },
    // {
    //     name:"Ella Turner",
    //     position:["Head of Product Management","Head of Community Relations"],
    //     imgLink:"/assets/team/ella_turner.png",
    //     category:["Talent","Policy Council"]
    // },
    // {
    //     name:"Ethan Porter",
    //     position:["Head of Business Development","Business Development Lead"],
    //     imgLink:"/assets/team/ethan_porter.png",
    //     category:["Talent","Collaborators"]
    // },
    // {
    //     name:"Oliver Quinn",
    //     position:["Talent Development Lead","Senior Blockchain Developer"],
    //     imgLink:"/assets/team/madison_hale.png",
    //     category:["Talent",'Collaborators']
    // },
    // {
    //     name:"Madison Hale",
    //     position:["Legal Advisor","Lead UI/UX Designer"],
    //     imgLink:"/assets/team/oliver_quinn.png",
    //     category:["Legal & Policy","Design"]
    // }
]


export const Team = () => {
    const [store, setStore] = useState(person)
    const [team, setTeam] = useState("Complete Team")

    const fillter = (param)=>{
        setTeam(param)
        if(param === "Complete Team") return setStore(person);

        let fl = person.filter((item)=>{
            return item.category.includes(param)
        })
        setStore(fl)
    }

    useEffect(()=>{
        const wow = new WOW()
        wow.init()
      },[])
    return (
        <main className={style.team + " d-flex flex-wrap flex-xl-nowrap"}>
            <div className={style.left+" d-flex flex-column justify-content-between"}>
                <div className={style.top}>
                    <ul className='list-unstyled'>
                        <li className={team=='Complete Team'&&style.active+' d-flex justify-content-between align-items-center'} onClick={()=>{fillter("Complete Team")}}>
                            <span>Complete Team</span>
                            <img src="/assets/arrow.svg" alt="" />
                        </li>
                        <li className={team=='Leadership'&&style.active+' d-flex justify-content-between align-items-center'} onClick={()=>{fillter("Leadership")}}>
                            <span>Leadership</span>
                            <img src="/assets/arrow.svg" alt="" />
                        </li>
                        <li>
                            <span>Investment & Research</span>
                        </li>
                        <ul className='list-unstyled ps-3'>
                                <li className={team=='Talent'&&style.active+' d-flex justify-content-between align-items-center'} onClick={()=>{fillter("Talent")}}>
                                    <span>Talent</span>
                                    <img src="/assets/arrow.svg" alt="" />
                                </li>
                                <li className={team=='Legal & Policy'&&style.active+' d-flex justify-content-between align-items-center'} onClick={()=>{fillter("Legal & Policy")}}>
                                    <span>Legal & Policy</span>
                                    <img src="/assets/arrow.svg" alt="" />
                                </li>
                                <li className={team=='Design'&&style.active+' d-flex justify-content-between align-items-center'} onClick={()=>{fillter("Design")}}>
                                    <span>Design</span>
                                    <img src="/assets/arrow.svg" alt="" />
                                </li>
                            </ul>
                        <li className={team=='Collaborators'&&style.active+' d-flex justify-content-between align-items-center'} onClick={()=>{fillter("Collaborators");}}>
                            <span>Collaborators</span>
                            <img src="/assets/arrow.svg" alt="" />
                        </li>
                        <li className={team=='Policy Council'&&style.active+' d-flex border-0 justify-content-between align-items-center'} onClick={()=>{fillter("Policy Council")}}>
                            <span>Policy Council</span>
                            <img src="/assets/arrow.svg" alt="" />
                        </li>
                    </ul>
                </div>
                <div className={style.social+" d-flex p-4 align-items-center justify-content-between w-100"}>
                <p className='mb-0'>Follow</p>
            <div className='d-flex gap-3'>
              <Link to="#" target="_blank">
              <i className="bi bi-twitter-x"></i>
              </Link>
              <Link to="#" target="_blank">
                <i className="bi bi-linkedin"></i>
              </Link>
              <Link to="#" target="_blank">
                <i className="bi bi-telegram"></i>
              </Link>
            </div>
                </div>
            </div>
            <div className={style.center + " d-flex flex-wrap align-content-start flex-grow-1"}>
                {
                    store.map((item, index)=>(
                        <Person person={item} key={index}/>
                    ))
                }

            </div>
            {/* <Right /> */}
        </main>
    )
}
