import React, { useEffect, useState } from 'react'
import style from "./follio.module.css"
import { Link } from 'react-router-dom'
// https://dydx.exchange/logo.svg
export const Card = ({ data }) => {
  return (
    <div className={`${style.card} d-flex gap-md-3 gap-sm-2 gap-3 animate__animated animate__fadeInDown`}>
      <div className='d-flex justify-content-center align-items-center' style={{background:data.bg}}>
        <img src={data.logo} alt="img" />
      </div>
      <div className='d-flex flex-column justify-content-between'>
        <div className='mt-lg-1 mt-2'>
          <h3>{data.title}</h3>
          <p className='mb-0'>{data.about}</p>
        </div>
        <Link to={data.website} target='_blank' className='d-flex justify-content-center align-items-center'>
          <svg width="11" height="11" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.203125 4.77783H43.4727V48.0431" strokeWidth="9" />
            <path d="M39.5625 8.47949L4.44141 42.8491" strokeWidth="9" />
          </svg>
        </Link>
      </div>
    </div>
  )
}
