import React from 'react'
import style from "./source.module.css"
import { Link } from 'react-router-dom'

export const Card = ({data}) => {
    return (
        <div className={style.card + ' d-flex gap-3 animate__animated animate__fadeInDown'}>
            <div className={style.imgdiv + " d-flex justify-content-center align-items-center"} style={{background:data.bg}}>
                {data.imgLink?
                <img src={data.imgLink || "/assets/souce1.svg"} alt="" width={"100%"} style={{maxWidth:"100%"}} />
                :<span className=' fs-1 opacity-100'>Flux</span>
                }
            </div>
            <div className={style.right+' d-flex flex-column flex-grow-1 justify-content-between gap-2'} >
                <div>
                    <div className='d-flex gap-2 justify-content-between align-items-start'>
                        <h2>{data.title}</h2>
                        <div className='d-flex gap-2'>
                            <div className='d-flex align-items-center gap-1'>
                                <img src="/assets/star.svg" alt="" />
                                <span>8852</span>
                            </div>
                            <div className='d-flex align-items-center gap-1'>
                                <img src="/assets/user.svg" alt="" />
                                <span>8852</span>
                            </div>
                        </div>
                    </div>
                    <p className='mb-0'>{data.text.length>120? data.text.substring(0,120)+"...":data.text}</p>
                </div>
                <div className={style.content + ' d-flex justify-content-between align-items-center'}>
                    <div className='d-flex gap-2'>
                        {/* <div className={style.tag}><a href='#' className='text-decoration-none mb-0'>Blog</a></div> */}
                        {data.git&&<div className={style.tag}><a href={data.git} target='_blank' className='text-decoration-none mb-0'>GitHub</a></div>}
                        {data.doc&&<div className={style.tag}><a href={data.doc} target='_blank' className='text-decoration-none mb-0'>Docs</a></div>}
                        {data.web&&<div className={style.tag}><a href={data.web} target='_blank' className='text-decoration-none mb-0'>Website</a></div>}
                    </div>
                    <Link to="#" className='d-flex justify-content-center align-items-center'>
                        <svg width="11" height="11" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.203125 4.77783H43.4727V48.0431" strokeWidth="9" />
                            <path d="M39.5625 8.47949L4.44141 42.8491" strokeWidth="9" />
                        </svg>
                    </Link>
                </div>
            </div>
        </div>
    )
}
