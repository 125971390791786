import React from 'react'
import style from "./writing.module.css"
import { Link } from 'react-router-dom'

export const ArCard = ({ data }) => {
    // console.log(data)
    return (
        <div className={style.card + ' d-flex gap-md-3 gap-2 animate__animated animate__fadeInDown'}>
            <img src={data.imgLink || "/assets/demo.png"} alt="" />
            <div className={style.right + ' flex-grow-1'} >
                <div className='position-relative' >
                    <h2>{data.title}</h2>
                    <p>{data.text.length > 150 ? data.text.substring(0, 120) + "..." : data.text}</p>
                </div>
                <div className={style.content + ' d-flex justify-content-between align-items-center'}>
                    <div className='d-flex gap-4'>
                        <p className='mb-0'>02.08.2025</p>
                        <p className='mb-0'>Alien.fi</p>
                    </div>
                    <Link to={data.link} target='_blank' className='d-flex justify-content-center align-items-center'>
                        <svg width="11" height="11" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.203125 4.77783H43.4727V48.0431" strokeWidth="9" />
                            <path d="M39.5625 8.47949L4.44141 42.8491" strokeWidth="9" />
                        </svg>
                    </Link>
                </div>
            </div>
        </div>
    )
}
