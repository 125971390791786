import React from 'react'
import style from './right.module.css'
import { Link } from 'react-router-dom'

export const Right = () => {
  return (
    <div className={style.right}>
      <div className={style.one + " py-3 px-4 d-lg-flex justify-content-end align-items-center"}>
        <Link to="/writing">
          <img className='wow animate__animated animate__zoomIn' src="/assets/arrow-green.svg" alt="" />
        </Link>
      </div>
      <div className={style.two}>
        <div className='d-flex gap-4 align-items-center p-3'>
          <h3>WRITINGS </h3>
          <Link to="/writing" className='d-flex justify-content-center align-items-center'>
            <svg width="11" height="11" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.203125 4.77783H43.4727V48.0431" strokeWidth="9" />
              <path d="M39.5625 8.47949L4.44141 42.8491" strokeWidth="9" />
            </svg>
          </Link>
          {/* <img src="/assets/arrow.svg" alt="" /> */}
        </div>
        <div className='d-flex flex-column'>
          <div className={style.artical+' mt-3 wow animate__animated animate__fadeInUp p-3'}>
            <img src="https://miro.medium.com/v2/resize:fit:720/format:webp/1*INbpOx32pwQLvDZiWoyL1Q.jpeg" alt="" width="100%" />
            <span>02.08.2025</span>
            <p>VCs vs. DAOs: The Unfinished Battle for Crypto Governance</p>
          </div>
          <div className={style.artical+' mt-5 wow animate__animated animate__fadeInUp p-3'}>
            <img src="https://miro.medium.com/v2/resize:fit:720/format:webp/1*P_C9N6EdhYPNjrzahs-vNQ.jpeg" alt="" width="100%" />
            <span>02.08.2025</span>
            <p>ZK vs. Optimistic Rollups: Why the Debate is Just Getting Started</p>
          </div>
        </div>
      </div>
    </div>
  )
}
