import React, { useEffect, useRef } from 'react'
import Style from "./home.module.css"
import Spline from '@splinetool/react-spline';
import { Link } from 'react-router-dom'
import {WOW} from "wowjs"

export const Home = () => {
  let object_ref = useRef()

  const onload = (spline) => {
    let obj = spline
    let newObj = spline.findObjectByName("Scene 1")
    object_ref.current = newObj
    // console.log(newObj)
  }

  const onhover = () => {
    // console.log(object_ref.current.position)
  }

  const handleMoment = (e) => {
    let { movementX, movementY, target } = e;
    let y = movementY * 1.5
    if (movementY * 1.5 > 4 || movementY * 1.5 < -4) {
      if (movementY >= 0) {
        y = 4
      } else {
        y = -4
      }
    }
    let x = movementX * 2
    if (movementX * 2 > 20 || movementX * 2 < -20) {
      if (movementX >= 0) {
        x = 20
      } else {
        x = -20
      }
    } else {
      x = Math.random().toFixed(2) * 100;
      x = x > 50 ? x / 2 : x
    }
    target.style.transform = `skew(${y > 0 ? 0 - x : x}deg, ${y}deg) translate3d(0,0,0)`
    // console.log(e)
  }


  const leave_handler = (e) => {
    e.target.style.transform = `skew(0deg, 0deg) translate3d(0,0,0)`
  }

  useEffect(()=>{
    const wow = new WOW()
    wow.init()
  },[])


  return (
    <main className={Style.home + " position-relative  d-xl-flex justify-content-between"}>
      <div className={Style.left}>
        <div className={Style.one + " d-none d-xl-block"}>

        </div>
        <div className={Style.two + " d-flex flex-column justify-content-between"}>
          <div className='d-flex gap-4 align-items-center'>
            <h3 >ABOUT</h3>
            <Link to="/about" className='d-flex justify-content-center align-items-center'>
              <svg width="11" height="11" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.203125 4.77783H43.4727V48.0431"  strokeWidth="9" />
                <path d="M39.5625 8.47949L4.44141 42.8491" strokeWidth="9" />
              </svg>
            </Link>
            {/* <img src="/assets/arrow.svg" alt="" /> */}
          </div>
          <div>
            <p>Alien.Fi is a research-driven crypto investment firm that funds companies and protocols from their earliers stages.</p>
            {/* <p>companies and protocols from their
              earliest stages.</p> */}
          </div>
        </div>
        <div className={Style.three + " d-flex flex-column justify-content-between"}>
          <div className='d-flex gap-4 align-items-center'>
            <h3>OPEN SOURCE</h3>
            <Link to="/open-source" className='d-flex justify-content-center align-items-center'>
              <svg width="11" height="11" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.203125 4.77783H43.4727V48.0431"  strokeWidth="9" />
                <path d="M39.5625 8.47949L4.44141 42.8491" strokeWidth="9" />
              </svg>
            </Link>
            {/* <img src="/assets/arrow.svg" alt="" /> */}
          </div>
          <div>
            <p>Alternative investments as uncorrelated assets allow you to diversify your risk-adjusted returns.</p>
            {/* <p>companies and protocols from their
              earliest stages.</p> */}
          </div>
        </div>
      </div>
      <div className={Style.center + " flex-grow-1 d-flex justify-content-center align-items-center object_div"}>
        <div className={Style.title_div + ' position-absolute'}>
          <h1 className='wow animate__animated animate__rubberBand' onMouseMove={handleMoment} onMouseLeave={leave_handler}>SHAPING THE</h1>
          <h1 className='wow text-end position-absolute animate__animated animate__rubberBand' onMouseMove={handleMoment} onMouseLeave={leave_handler}>FUTURE</h1>
        </div>
        <div>
          {/* <img src="/assets/home.png" alt="" /> */}
          <Spline
            // scene="https://prod.spline.design/huCdDuTm2EoZOnNq/scene.splinecode"
            scene='https://prod.spline.design/sPlzj4eFQNILyF-a/scene.splinecode'
            width={100}
            height={100}
            onLoad={onload}
            onMouseEnter={onhover}
            id='object'
          />
        </div>
      </div>
      <div className={Style.right}>
        <div className={Style.one + " py-3 px-4 d-xl-flex justify-content-end align-items-center"}>
          <Link to="/about">
            <img className='wow animate__animated animate__zoomIn' src="/assets/arrow-green.svg" alt="" />
          </Link>
        </div>
        <div className={Style.two + " d-flex flex-column justify-content-between"}>
          <div></div>
          <div className={Style.social+' d-flex justify-content-between align-items-center'}>
            <p className='mb-0'>Follow</p>
            <div className='d-flex gap-3'>
              <Link to="#" target="_blank">
              <i className="bi bi-twitter-x"></i>
              </Link>
              <Link to="#" target="_blank">
                <i className="bi bi-linkedin"></i>
              </Link>
              <Link to="#" target="_blank">
                <i className="bi bi-telegram"></i>
              </Link>
            </div>
          </div>
        </div>
        <div className={Style.three + " d-flex flex-column justify-content-between"}>
          <div className='d-flex gap-4 align-items-center'>
            <h3>COLLABORATE </h3>
            <Link to="/team" className='d-flex justify-content-center align-items-center'>
              <svg width="11" height="11" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.203125 4.77783H43.4727V48.0431"  strokeWidth="9" />
                <path d="M39.5625 8.47949L4.44141 42.8491" strokeWidth="9" />
              </svg>
            </Link>
            {/* <img src="/assets/arrow.svg" alt="" /> */}
          </div>
          <div>
            <p>Join our hub of builders, entrepeneurs and private equity as a resource or investor.</p>
          </div>
        </div>
      </div>
    </main>
  )
}
