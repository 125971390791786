import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import style from "./navFooter.module.css"

export const Navbar = () => {
    const [openMenu, setMenu] = useState(false)
    const [showHeader, setShowHeader] = useState(false)
    const { pathname } = useLocation()

    const lastScrollY = useRef(0)


    const handleScroll = () => {
        const currentScrollY = window.scrollY;

        let target = document.querySelector("#header")
        if (currentScrollY > 100) {
            target.classList.add("active_nav")

        } else {
            target.classList.remove("active_nav")
            lastScrollY.current = 0
            return setShowHeader(false)
        }

        if (currentScrollY > lastScrollY.current) {
            setShowHeader(false);
        } else {
            setShowHeader(true);
        }
        if (lastScrollY.current + 10 <= currentScrollY || lastScrollY.current - 10 >= currentScrollY) {
            lastScrollY.current = currentScrollY;
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, [])


    const opner = () => {
        let target = document.querySelector(".mobileNav")
        if (openMenu) return target.style.display = "flex"
        setTimeout(() => {
            target.style.display = "none"
        }, 1000);
    }


    useEffect(() => {
        opner()
    }, [openMenu])
    return (
        <nav className={style.nav} id='header' style={{ top: showHeader ? "0" : openMenu ? "0" : "-100px" }}>
            <div className='d-flex justify-content-between position-relative'>
                <div className={style.logo}>
                    <Link to="/" className='d-flex align-items-start gap-2'>
                        <img src="/assets/logo1.svg" alt="" />
                        <span>Alien.fi</span>
                    </Link>
                </div>
                <button className={style.menu + " d-md-none"} onClick={() => { setMenu(!openMenu) }}>
                    <span className='mb-2' style={{ transform: openMenu ? "translate(0px, 6px) rotate(45deg)" : "rotate(0)" }}></span>
                    <span style={{ transform: openMenu ? "translate(0px, -5px) rotate(-45deg)" : "rotate(0)" }}></span>
                </button>

                <div className={style.right+" d-none d-md-block"}>
                    <ul className='list-unstyled d-flex flex-column flex-md-row align-items-center gap-md-3 mb-0'>
                        <li className={`${pathname === "/about" && style.active}`}>
                            <Link to="/about" onClick={() => { setMenu(false) }}>About</Link>
                        </li>
                        <li className={`${pathname === "/team" && style.active}`}>
                            <Link to="/team" onClick={() => { setMenu(false) }}>Team</Link>
                        </li>
                        <li className={`${pathname === "/portfolio" && style.active}`}>
                            <Link to="/portfolio" onClick={() => { setMenu(false) }}>Portfolio</Link>
                        </li>
                        <li className={`${pathname === "/writing" && style.active}`}>
                            <Link to="/writing" onClick={() => { setMenu(false) }}>Writing</Link>
                        </li>
                        <li className={`${pathname === "/open-source" && style.active}`}>
                            <Link to="/open-source" onClick={() => { setMenu(false) }}>Open Source</Link>
                        </li>
                        <li className={`${pathname === "/careers" && style.active}`}>
                            <Link to="/careers" onClick={() => { setMenu(false) }}>Careers</Link>
                        </li>
                    </ul>
                </div>
                <div className={`${style.right} d-md-none flex-column justify-content-between mobileNav`}
                // style={{ top: openMenu ?"0": "-120vh" }}
                >
                    <ul className='list-unstyled d-flex flex-column flex-md-row align-items-center gap-md-3 mb-0'>
                        <li
                            className={`${pathname === "/about" && style.active} ${openMenu ? "animate__fadeInDown" : "animate__fadeOutUpBig"} animate__animated`}
                            style={{ animationDelay: "0s" }}
                        >
                            <Link to="/about" onClick={() => { setMenu(false) }}>About</Link>
                            <span>
                                <svg width="11" height="11" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.203125 4.77783H43.4727V48.0431" strokeWidth="9" />
                                    <path d="M39.5625 8.47949L4.44141 42.8491" strokeWidth="9" />
                                </svg>
                            </span>
                        </li>
                        <li
                            className={`${pathname === "/team" && style.active} ${openMenu ? "animate__fadeInDown" : "animate__fadeOutUpBig"} animate__animated`}
                            style={{ animationDelay: ".1s" }}
                        >
                            <Link to="/team" onClick={() => { setMenu(false) }}>Team</Link>
                            <span>
                                <svg width="11" height="11" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.203125 4.77783H43.4727V48.0431" strokeWidth="9" />
                                    <path d="M39.5625 8.47949L4.44141 42.8491" strokeWidth="9" />
                                </svg>
                            </span>
                        </li>
                        <li
                            className={`${pathname === "/portfolio" && style.active} ${openMenu ? "animate__fadeInDown" : "animate__fadeOutUpBig"} animate__animated`}
                            style={{ animationDelay: ".2s" }}
                        >
                            <Link to="/portfolio" onClick={() => { setMenu(false) }}>Portfolio</Link>
                            <span>
                                <svg width="11" height="11" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.203125 4.77783H43.4727V48.0431" strokeWidth="9" />
                                    <path d="M39.5625 8.47949L4.44141 42.8491" strokeWidth="9" />
                                </svg>
                            </span>
                        </li>
                        <li
                            className={`${pathname === "/writing" && style.active} ${openMenu ? "animate__fadeInDown" : "animate__fadeOutUpBig"} animate__animated`}
                            style={{ animationDelay: ".3s" }}
                        >
                            <Link to="/writing" onClick={() => { setMenu(false) }}>Writing</Link>
                            <span>
                                <svg width="11" height="11" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.203125 4.77783H43.4727V48.0431" strokeWidth="9" />
                                    <path d="M39.5625 8.47949L4.44141 42.8491" strokeWidth="9" />
                                </svg>
                            </span>
                        </li>
                        <li
                            className={`${pathname === "/open-source" && style.active} ${openMenu ? "animate__fadeInDown" : "animate__fadeOutUpBig"} animate__animated`}
                            style={{ animationDelay: ".4s" }}
                        >
                            <Link to="open-source" onClick={() => { setMenu(false) }}>Open Source</Link>
                            <span>
                                <svg width="11" height="11" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.203125 4.77783H43.4727V48.0431" strokeWidth="9" />
                                    <path d="M39.5625 8.47949L4.44141 42.8491" strokeWidth="9" />
                                </svg>
                            </span>
                        </li>
                        <li
                            className={`${pathname === "/careers" && style.active} ${openMenu ? "animate__fadeInDown" : "animate__fadeOutUpBig"} animate__animated`}
                            style={{ animationDelay: ".5s" }}
                        >
                            <Link to="/careers" onClick={() => { setMenu(false) }}>Careers</Link>
                            <span>
                                <svg width="11" height="11" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.203125 4.77783H43.4727V48.0431" strokeWidth="9" />
                                    <path d="M39.5625 8.47949L4.44141 42.8491" strokeWidth="9" />
                                </svg>
                            </span>
                        </li>
                    </ul>

                    <div className={`${style.social} + " d-flex d-md-none align-items-center justify-content-between w-100 animate__animated ${openMenu ? "animate__fadeInUp" : "animate__fadeOutDown"} `}>
                        <p className='mb-0'>Follow</p>
                        <div className='d-flex gap-3'>
                            <Link to="#" target="_blank">
                                <i className="bi bi-twitter-x"></i>
                            </Link>
                            <Link to="#" target="_blank">
                                <i className="bi bi-linkedin"></i>
                            </Link>
                            <Link to="#" target="_blank">
                                <i className="bi bi-telegram"></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}
