import React, { useEffect } from 'react'
import style from "./follio.module.css"
import { Right } from '../../Components/Common/Right'
import { Link } from 'react-router-dom'
import { Card } from './Card'
import { WOW } from "wowjs"

const cardArr= [
  {
    title:"Uniswap",
    about:"The leading decentralized exchange, enabling permissionless token swaps on Ethereum.",
    logo:"/assets/uniswap.svg",
    website:"https://uniswap.org/",
    bg:"#e765e4"
  },
  {
    title:"dYdX",
    about:"A decentralized exchange for margin trading, derivatives, and other advanced financial products.",
    logo:"https://dydx.exchange/logo.svg",
    website:"https://dydx.exchange/",
    bg:"#5f68f9"
  },
  {
    title:"Compound",
    about:"An autonomous interest rate protocol that powers lending and borrowing markets on Ethereum.",
    // logo:"https://compound.finance/images/compound-logo.svg",
    logo:"/assets/portfolio_img/compound-logo.svg",
    website:"https://compound.finance/",
    bg:"#060a0e"
  },
  {
    title:"MakerDAO",
    about:"The creator of DAI, a decentralized stablecoin protocol that underpins much of DeFi’s stability.",
    logo:"/assets/makerdao.svg",
    website:"https://makerdao.com/",
    bg:"#ffffff"
  },

  {
    title:"Synthetix",
    about:"A derivatives liquidity protocol that enables the creation of synthetic assets on Ethereum.",
    logo:"https://synthetix.io/images/webclip.png",
    website:"https://synthetix.io/",
    bg:"#0e053e"
  },
  {
    title:"Lido",
    about:"A staking solution for proof-of-stake networks, providing liquidity for staked assets",
    logo:"https://lido.fi/favicon.svg",
    website:"https://lido.fi/",
    bg:"rgb(246 173 255)"
  },
  {
    title:"Optimism",
    about:"A Layer 2 scaling solution for Ethereum that offers fast, low-cost transactions.",
    // logo:"https://www.optimism.io/optimism.svg",
    logo:"/assets/portfolio_img/optimism.svg",
    website:"https://optimism.io/",
    bg:"#ff0420"
  },
  {
    title:"StarkWare",
    about:"A cryptographic engine focused on scalability and privacy solutions using STARK proofs.",
    // logo:"https://starkware.co/wp-content/uploads/2021/04/logotype.svg",
    logo:"/assets/portfolio_img/stark.svg",
    website:"https://starkware.co/",
    bg:"#23296b"
  },
  {
    title:"Aztec",
    about:"A protocol for on-chain privacy, enabling shielded transactions on Ethereum.",
    logo:"https://cdn.prod.website-files.com/65804cbd4c37d195e67717e9/6582f05437cbe4ed544fd968_Aztec%20Logo%20-%20Lockup%20Light.svg",
    website:"https://aztec.network/",
    bg:"#705fbe"
  },
  {
    title:"OpenSea",
    about:"The largest NFT marketplace, enabling users to buy, sell, and discover unique digital assets.",
    logo:"https://opensea.io/static/images/logos/opensea-logo.svg",
    website:"https://opensea.io/",
    bg:"#0083de"
  },
  
]

export const Portfollio = () => {
  useEffect(() => {
    const wow = new WOW()
    wow.init()
  }, [])
  return (
    <main className={style.follio + " d-flex flex-wrap flex-xl-nowrap"}>
      <div className={style.left + " d-flex flex-column justify-content-between"}>
        <div className={style.top + " p-sm-4 p-3 wow animate__animated animate__fadeInUp"}>
          <p className='para'>Alien.fi builds and contributes to projects that advance the frontier.</p>
          <p className='para'>We believe in doing so even when there may not be a direct commercial incentive.</p>
        </div>
        <div className={style.social + " d-flex p-4 align-items-center justify-content-between w-100"}>
          <p className='mb-0'>Follow</p>
          <div className='d-flex gap-3'>
            <Link to="#" target="_blank">
              <i className="bi bi-twitter-x"></i>
            </Link>
            <Link to="#" target="_blank">
              <i className="bi bi-linkedin"></i>
            </Link>
            <Link to="#" target="_blank">
              <i className="bi bi-telegram"></i>
            </Link>
          </div>
        </div>
      </div>
      <div className={style.center + " d-flex flex-wrap flex-grow-1"}>
        {
          cardArr.map((item, i)=>(
            <Card data={item} key={i}/>
          ))
        }
      
      </div>
      {/* <Right /> */}
    </main>
  )
}
